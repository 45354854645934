export const AppStoreBadgeComponent = ({ ...props }: any) => (
  <svg
    height="100%"
    viewBox="0 0 163 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="163" height="48" rx="12" fill="white" />
    <path
      d="M29.7239 24.361C29.7368 23.3594 30.0029 22.3773 30.4973 21.5062C30.9917 20.6351 31.6985 19.9031 32.5518 19.3785C32.0097 18.6043 31.2946 17.9672 30.4632 17.5177C29.6318 17.0683 28.7071 16.8189 27.7624 16.7893C25.7473 16.5778 23.7938 17.9951 22.7669 17.9951C21.7202 17.9951 20.1392 16.8103 18.4367 16.8453C17.3355 16.8809 16.2623 17.2011 15.3217 17.7748C14.381 18.3485 13.605 19.156 13.0692 20.1188C10.7484 24.1369 12.4795 30.0421 14.7027 33.2901C15.815 34.8805 17.1149 36.657 18.8158 36.594C20.4803 36.525 21.102 35.5327 23.1111 35.5327C25.1016 35.5327 25.6849 36.594 27.4203 36.554C29.2064 36.525 30.3317 34.9565 31.4049 33.351C32.2041 32.2178 32.8191 30.9653 33.227 29.64C32.1894 29.2011 31.3039 28.4665 30.6809 27.5278C30.058 26.589 29.7252 25.4876 29.7239 24.361Z"
      fill="#252746"
    />
    <path
      d="M26.446 14.6532C27.4198 13.4842 27.8996 11.9816 27.7834 10.4645C26.2956 10.6208 24.9213 11.3318 23.9343 12.456C23.4517 13.0052 23.0821 13.6442 22.8467 14.3363C22.6112 15.0284 22.5144 15.7602 22.5619 16.4897C23.306 16.4974 24.0422 16.3361 24.715 16.018C25.3878 15.6999 25.9797 15.2332 26.446 14.6532Z"
      fill="#252746"
    />
    <path
      d="M50.763 32.5677H45.0829L43.7188 36.5954H41.313L46.6931 21.6938H49.1927L54.5727 36.5954H52.1258L50.763 32.5677ZM45.6712 30.7091H50.1736L47.954 24.1724H47.8919L45.6712 30.7091Z"
      fill="#252746"
    />
    <path
      d="M66.1919 31.1638C66.1919 34.54 64.3848 36.7091 61.6579 36.7091C60.9671 36.7452 60.2801 36.5861 59.6756 36.2499C59.071 35.9138 58.5733 35.4142 58.2395 34.8083H58.1879V40.1896H55.9579V25.731H58.1165V27.538H58.1575C58.5066 26.935 59.0127 26.438 59.6219 26.0998C60.231 25.7616 60.9205 25.5948 61.6169 25.6173C64.3742 25.6173 66.1919 27.797 66.1919 31.1638ZM63.8997 31.1638C63.8997 28.9642 62.763 27.5181 61.0286 27.5181C59.3247 27.5181 58.1786 28.9946 58.1786 31.1638C58.1786 33.3529 59.3247 34.8189 61.0286 34.8189C62.763 34.8189 63.8997 33.3833 63.8997 31.1638Z"
      fill="#252746"
    />
    <path
      d="M78.1497 31.1638C78.1497 34.54 76.3427 36.7091 73.6157 36.7091C72.9249 36.7452 72.2379 36.5861 71.6334 36.2499C71.0288 35.9138 70.5311 35.4142 70.1973 34.8083H70.1457V40.1896H67.9157V25.731H70.0742V27.538H70.1153C70.4644 26.935 70.9705 26.438 71.5796 26.0998C72.1888 25.7616 72.8783 25.5948 73.5746 25.6173C76.3321 25.6173 78.1497 27.797 78.1497 31.1638ZM75.8575 31.1638C75.8575 28.9642 74.7208 27.5181 72.9864 27.5181C71.2825 27.5181 70.1364 28.9946 70.1364 31.1638C70.1364 33.3529 71.2825 34.8189 72.9864 34.8189C74.7208 34.8189 75.8575 33.3833 75.8575 31.1638Z"
      fill="#252746"
    />
    <path
      d="M86.0528 32.4435C86.2181 33.9212 87.6536 34.8915 89.6153 34.8915C91.495 34.8915 92.8474 33.9212 92.8474 32.5887C92.8474 31.4321 92.0317 30.7395 90.1005 30.2649L88.1692 29.7997C85.4329 29.1387 84.1626 27.859 84.1626 25.7825C84.1626 23.2114 86.4032 21.4454 89.5849 21.4454C92.7337 21.4454 94.8923 23.2114 94.9649 25.7825H92.7137C92.579 24.2954 91.3497 23.3977 89.5532 23.3977C87.7567 23.3977 86.5274 24.3059 86.5274 25.6278C86.5274 26.6813 87.3126 27.3012 89.2333 27.7758L90.875 28.1789C93.9325 28.902 95.2028 30.1301 95.2028 32.3098C95.2028 35.0977 92.9821 36.8438 89.4501 36.8438C86.1454 36.8438 83.9141 35.1387 83.77 32.4434L86.0528 32.4435Z"
      fill="#252746"
    />
    <path
      d="M100.016 23.1599V25.731H102.082V27.497H100.016V33.4864C100.016 34.4169 100.429 34.8505 101.338 34.8505C101.583 34.8462 101.828 34.829 102.071 34.7989V36.5544C101.663 36.6307 101.248 36.6652 100.832 36.6575C98.6329 36.6575 97.7751 35.8313 97.7751 33.7243V27.497H96.1954V25.731H97.775V23.1599H100.016Z"
      fill="#252746"
    />
    <path
      d="M103.278 31.1638C103.278 27.7454 105.292 25.5974 108.431 25.5974C111.581 25.5974 113.585 27.7454 113.585 31.1638C113.585 34.5915 111.592 36.7302 108.431 36.7302C105.272 36.7302 103.278 34.5915 103.278 31.1638ZM111.313 31.1638C111.313 28.8189 110.238 27.4349 108.431 27.4349C106.624 27.4349 105.551 28.8294 105.551 31.1638C105.551 33.5181 106.624 34.8915 108.431 34.8915C110.238 34.8915 111.313 33.5181 111.313 31.1638Z"
      fill="#252746"
    />
    <path
      d="M115.424 25.731H117.55V27.5802H117.602C117.746 27.0026 118.084 26.4922 118.56 26.1348C119.036 25.7773 119.621 25.5946 120.215 25.6173C120.472 25.6164 120.729 25.6443 120.979 25.7005V27.7864C120.655 27.6873 120.317 27.6418 119.977 27.6517C119.653 27.6385 119.33 27.6956 119.031 27.8191C118.731 27.9426 118.461 28.1295 118.241 28.3669C118.02 28.6044 117.853 28.8868 117.752 29.1948C117.65 29.5028 117.617 29.8291 117.654 30.1513V36.5954H115.424L115.424 25.731Z"
      fill="#252746"
    />
    <path
      d="M131.261 33.4044C130.961 35.3767 129.041 36.7302 126.583 36.7302C123.423 36.7302 121.461 34.6126 121.461 31.2153C121.461 27.8075 123.433 25.5974 126.49 25.5974C129.495 25.5974 131.386 27.6622 131.386 30.9564V31.7204H123.712V31.8552C123.677 32.255 123.727 32.6578 123.859 33.0368C123.991 33.4158 124.203 33.7624 124.479 34.0534C124.755 34.3445 125.091 34.5734 125.462 34.725C125.834 34.8765 126.234 34.9473 126.635 34.9325C127.162 34.9819 127.691 34.8598 128.143 34.5845C128.595 34.3092 128.946 33.8953 129.144 33.4044L131.261 33.4044ZM123.723 30.1618H129.154C129.174 29.8023 129.12 29.4425 128.994 29.1051C128.869 28.7676 128.675 28.4598 128.424 28.2009C128.174 27.942 127.873 27.7375 127.54 27.6005C127.207 27.4634 126.85 27.3967 126.49 27.4044C126.126 27.4022 125.766 27.472 125.43 27.6097C125.094 27.7474 124.789 27.9503 124.532 28.2066C124.274 28.463 124.07 28.7678 123.932 29.1033C123.793 29.4389 123.722 29.7987 123.723 30.1618Z"
      fill="#252746"
    />
    <path
      d="M45.3917 10.4773C45.8592 10.4438 46.3284 10.5144 46.7653 10.6841C47.2022 10.8538 47.5961 11.1184 47.9184 11.4588C48.2407 11.7991 48.4834 12.2067 48.6291 12.6523C48.7748 13.0978 48.8198 13.5701 48.7608 14.0351C48.7608 16.3226 47.5245 17.6375 45.3917 17.6375H42.8054V10.4773H45.3917ZM43.9175 16.6249H45.2675C45.6015 16.6449 45.9359 16.5901 46.2461 16.4646C46.5564 16.339 46.8347 16.1459 47.0609 15.8992C47.2871 15.6525 47.4554 15.3585 47.5537 15.0386C47.6519 14.7186 47.6776 14.3808 47.6288 14.0497C47.674 13.7199 47.6458 13.3842 47.546 13.0666C47.4463 12.749 47.2775 12.4574 47.0519 12.2127C46.8262 11.968 46.5492 11.7762 46.2407 11.6511C45.9322 11.526 45.5998 11.4707 45.2675 11.4892H43.9175V16.6249Z"
      fill="#252746"
    />
    <path
      d="M50.0171 14.9333C49.9831 14.5782 50.0237 14.2199 50.1363 13.8815C50.2489 13.543 50.431 13.2318 50.671 12.9678C50.9109 12.7038 51.2034 12.4929 51.5296 12.3486C51.8558 12.2042 52.2086 12.1297 52.5653 12.1297C52.922 12.1297 53.2748 12.2042 53.601 12.3486C53.9273 12.4929 54.2197 12.7038 54.4596 12.9678C54.6996 13.2318 54.8817 13.543 54.9943 13.8815C55.1069 14.2199 55.1475 14.5782 55.1135 14.9333C55.1482 15.2888 55.108 15.6476 54.9957 15.9866C54.8834 16.3256 54.7014 16.6374 54.4614 16.9019C54.2214 17.1664 53.9287 17.3778 53.6022 17.5224C53.2757 17.667 52.9225 17.7418 52.5653 17.7418C52.2082 17.7418 51.855 17.667 51.5284 17.5224C51.2019 17.3778 50.9092 17.1664 50.6692 16.9019C50.4292 16.6374 50.2472 16.3256 50.1349 15.9866C50.0226 15.6476 49.9824 15.2888 50.0171 14.9333ZM54.0167 14.9333C54.0167 13.762 53.4905 13.0771 52.5671 13.0771C51.6401 13.0771 51.1187 13.762 51.1187 14.9333C51.1187 16.114 51.6401 16.7937 52.5671 16.7937C53.4905 16.7937 54.0167 16.1093 54.0167 14.9333Z"
      fill="#252746"
    />
    <path
      d="M61.8882 17.6374H60.7819L59.6651 13.6577H59.5808L58.4686 17.6374H57.3729L55.8835 12.2339H56.9651L57.9331 16.3571H58.0128L59.1237 12.2339H60.1468L61.2577 16.3571H61.3421L62.3054 12.2339H63.3718L61.8882 17.6374Z"
      fill="#252746"
    />
    <path
      d="M64.6245 12.2339H65.651V13.0923H65.7307C65.8659 12.784 66.0939 12.5255 66.383 12.353C66.672 12.1804 67.0077 12.1023 67.3432 12.1296C67.6062 12.1098 67.8702 12.1495 68.1157 12.2456C68.3612 12.3417 68.5819 12.4918 68.7616 12.6848C68.9412 12.8778 69.0751 13.1088 69.1533 13.3606C69.2316 13.6124 69.2521 13.8785 69.2135 14.1394V17.6374H68.1471V14.4071C68.1471 13.5388 67.7698 13.1069 66.9811 13.1069C66.8026 13.0986 66.6244 13.129 66.4588 13.196C66.2931 13.263 66.1439 13.365 66.0213 13.4951C65.8988 13.6252 65.8058 13.7802 65.7488 13.9495C65.6917 14.1189 65.672 14.2986 65.6909 14.4763V17.6374H64.6245L64.6245 12.2339Z"
      fill="#252746"
    />
    <path
      d="M70.9128 10.1245H71.9792V17.6374H70.9128V10.1245Z"
      fill="#252746"
    />
    <path
      d="M73.4616 14.9333C73.4277 14.5782 73.4683 14.2199 73.5809 13.8814C73.6936 13.5429 73.8757 13.2317 74.1157 12.9677C74.3556 12.7037 74.6481 12.4928 74.9743 12.3485C75.3006 12.2041 75.6534 12.1296 76.0101 12.1296C76.3669 12.1296 76.7197 12.2041 77.0459 12.3485C77.3722 12.4928 77.6646 12.7037 77.9046 12.9677C78.1446 13.2317 78.3267 13.5429 78.4393 13.8814C78.552 14.2199 78.5926 14.5782 78.5587 14.9333C78.5932 15.2888 78.5531 15.6476 78.4407 15.9866C78.3284 16.3256 78.1463 16.6374 77.9063 16.9019C77.6663 17.1664 77.3736 17.3777 77.0471 17.5224C76.7205 17.667 76.3673 17.7417 76.0101 17.7417C75.653 17.7417 75.2998 17.667 74.9732 17.5224C74.6466 17.3777 74.354 17.1664 74.1139 16.9019C73.8739 16.6374 73.6919 16.3256 73.5795 15.9866C73.4672 15.6476 73.427 15.2888 73.4616 14.9333ZM77.4612 14.9333C77.4612 13.762 76.935 13.0771 76.0116 13.0771C75.0846 13.0771 74.5632 13.762 74.5632 14.9333C74.5632 16.114 75.0847 16.7937 76.0116 16.7937C76.935 16.7937 77.4612 16.1093 77.4612 14.9333Z"
      fill="#252746"
    />
    <path
      d="M79.6813 16.1093C79.6813 15.1366 80.4055 14.5759 81.6911 14.4962L83.1548 14.4118V13.9454C83.1548 13.3747 82.7774 13.0525 82.0485 13.0525C81.4532 13.0525 81.0407 13.271 80.9223 13.6531H79.8899C79.9989 12.7249 80.872 12.1296 82.0977 12.1296C83.4524 12.1296 84.2165 12.804 84.2165 13.9454V17.6374H83.1899V16.8781H83.1056C82.9343 17.1504 82.6938 17.3725 82.4087 17.5216C82.1235 17.6706 81.8039 17.7413 81.4825 17.7265C81.2557 17.75 81.0264 17.7258 80.8095 17.6554C80.5926 17.585 80.3928 17.4699 80.2231 17.3175C80.0534 17.1651 79.9175 16.9789 79.8242 16.7708C79.7309 16.5627 79.6822 16.3374 79.6813 16.1093ZM83.1548 15.6476V15.1958L81.8352 15.2802C81.0911 15.33 80.7536 15.5831 80.7536 16.0595C80.7536 16.5458 81.1755 16.8288 81.7556 16.8288C81.9256 16.846 82.0973 16.8289 82.2605 16.7784C82.4237 16.7278 82.5751 16.645 82.7056 16.5348C82.8361 16.4246 82.9432 16.2893 83.0203 16.1368C83.0975 15.9844 83.1432 15.818 83.1548 15.6476Z"
      fill="#252746"
    />
    <path
      d="M85.618 14.9333C85.618 13.2259 86.4958 12.1443 87.861 12.1443C88.1987 12.1287 88.5339 12.2096 88.8273 12.3775C89.1207 12.5453 89.3603 12.7933 89.518 13.0923H89.5977V10.1245H90.6641V17.6374H89.6423V16.7837H89.5579C89.388 17.0807 89.14 17.3255 88.8409 17.4917C88.5418 17.6579 88.2029 17.7391 87.861 17.7265C86.4864 17.7265 85.618 16.6448 85.618 14.9333ZM86.7196 14.9333C86.7196 16.0794 87.2599 16.7691 88.1634 16.7691C89.0622 16.7691 89.6177 16.0695 89.6177 14.938C89.6177 13.8118 89.0564 13.1023 88.1634 13.1023C87.2657 13.1023 86.7196 13.7966 86.7196 14.9333Z"
      fill="#252746"
    />
    <path
      d="M95.0762 14.9333C95.0423 14.5782 95.0829 14.2199 95.1955 13.8815C95.3081 13.543 95.4902 13.2318 95.7302 12.9678C95.9701 12.7038 96.2625 12.4929 96.5888 12.3486C96.915 12.2042 97.2678 12.1297 97.6245 12.1297C97.9812 12.1297 98.334 12.2042 98.6602 12.3486C98.9864 12.4929 99.2789 12.7038 99.5188 12.9678C99.7588 13.2318 99.9409 13.543 100.053 13.8815C100.166 14.2199 100.207 14.5782 100.173 14.9333C100.207 15.2888 100.167 15.6476 100.055 15.9866C99.9426 16.3256 99.7606 16.6374 99.5206 16.9019C99.2806 17.1664 98.9879 17.3778 98.6614 17.5224C98.3348 17.667 97.9816 17.7418 97.6245 17.7418C97.2673 17.7418 96.9141 17.667 96.5876 17.5224C96.261 17.3778 95.9684 17.1664 95.7284 16.9019C95.4884 16.6374 95.3064 16.3256 95.1941 15.9866C95.0818 15.6476 95.0416 15.2888 95.0762 14.9333ZM99.0759 14.9333C99.0759 13.762 98.5497 13.0771 97.6263 13.0771C96.6993 13.0771 96.1778 13.762 96.1778 14.9333C96.1778 16.114 96.6993 16.7937 97.6263 16.7937C98.5497 16.7937 99.0759 16.1093 99.0759 14.9333Z"
      fill="#252746"
    />
    <path
      d="M101.604 12.2339H102.63V13.0923H102.71C102.845 12.784 103.073 12.5255 103.362 12.353C103.651 12.1804 103.987 12.1023 104.322 12.1296C104.585 12.1098 104.849 12.1495 105.095 12.2456C105.34 12.3417 105.561 12.4918 105.741 12.6848C105.92 12.8778 106.054 13.1088 106.132 13.3606C106.211 13.6124 106.231 13.8785 106.193 14.1394V17.6374H105.126V14.4071C105.126 13.5388 104.749 13.1069 103.96 13.1069C103.782 13.0986 103.604 13.129 103.438 13.196C103.272 13.263 103.123 13.365 103 13.4951C102.878 13.6252 102.785 13.7802 102.728 13.9495C102.671 14.1189 102.651 14.2986 102.67 14.4763V17.6374H101.604V12.2339Z"
      fill="#252746"
    />
    <path
      d="M112.218 10.8886V12.2585H113.389V13.1568H112.218V15.9353C112.218 16.5013 112.452 16.7491 112.983 16.7491C113.118 16.7487 113.254 16.7405 113.389 16.7245V17.6128C113.198 17.6471 113.004 17.6653 112.809 17.6673C111.623 17.6673 111.151 17.2501 111.151 16.2083V13.1567H110.293V12.2585H111.151V10.8886H112.218Z"
      fill="#252746"
    />
    <path
      d="M114.846 10.1245H115.903V13.1023H115.987C116.129 12.7911 116.363 12.5312 116.658 12.3579C116.953 12.1847 117.294 12.1066 117.635 12.1343C117.896 12.1201 118.158 12.1637 118.401 12.2622C118.643 12.3606 118.861 12.5114 119.039 12.7038C119.217 12.8962 119.35 13.1255 119.429 13.3753C119.508 13.625 119.531 13.8891 119.496 14.1487V17.6374H118.428V14.4118C118.428 13.5487 118.026 13.1116 117.273 13.1116C117.09 13.0966 116.905 13.1218 116.733 13.1854C116.56 13.2491 116.404 13.3497 116.274 13.4802C116.144 13.6107 116.045 13.7679 115.982 13.9409C115.92 14.1138 115.896 14.2984 115.912 14.4815V17.6374H114.846L114.846 10.1245Z"
      fill="#252746"
    />
    <path
      d="M125.714 16.1784C125.569 16.6723 125.255 17.0995 124.827 17.3852C124.399 17.671 123.884 17.7972 123.372 17.7417C123.016 17.7511 122.663 17.6829 122.336 17.5419C122.009 17.4008 121.716 17.1903 121.479 16.925C121.241 16.6596 121.064 16.3457 120.96 16.0052C120.856 15.6646 120.828 15.3054 120.876 14.9527C120.829 14.5988 120.858 14.2389 120.962 13.8973C121.066 13.5557 121.242 13.2403 121.478 12.9726C121.714 12.705 122.005 12.4912 122.331 12.3457C122.657 12.2003 123.011 12.1266 123.368 12.1296C124.871 12.1296 125.778 13.1568 125.778 14.8536V15.2257H121.963V15.2855C121.946 15.4838 121.971 15.6834 122.036 15.8715C122.101 16.0596 122.204 16.232 122.34 16.3778C122.475 16.5235 122.64 16.6393 122.823 16.7178C123.005 16.7963 123.203 16.8357 123.402 16.8335C123.657 16.8641 123.915 16.8182 124.144 16.7015C124.373 16.5849 124.562 16.4028 124.687 16.1784L125.714 16.1784ZM121.963 14.437H124.692C124.705 14.2557 124.681 14.0735 124.619 13.9022C124.558 13.731 124.462 13.5744 124.337 13.4426C124.212 13.3108 124.06 13.2066 123.892 13.1368C123.724 13.067 123.544 13.0331 123.362 13.0372C123.177 13.0349 122.994 13.0695 122.823 13.1391C122.653 13.2086 122.497 13.3116 122.367 13.4421C122.237 13.5725 122.134 13.7278 122.064 13.8987C121.995 14.0695 121.96 14.2526 121.963 14.437Z"
      fill="#252746"
    />
  </svg>
);
